<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
    @keydown.esc="$emit(`close`)"
  >
    <v-card
      class="elevation-0 ma-auto"
      tile
      width="1400"
      :max-width="$vuetify.breakpoint.mobile ? '95%' : '70%'"
    >
      <div class="pl-16 ml-5 pr-10">
        <OffboardingProgressBar />
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            absolute
            left
            color="white"
            elevation="0"
            class="mt-3"
            v-bind="attrs"
            v-on="on"
            @click="cancel"
          >
            <v-icon v-text="'mdi-close'" color="secondary lighten-2" />
          </v-btn>
        </template>
        <span class="text-capitalize">{{ $t("action.close") }}</span>
      </v-tooltip>

      <v-window v-model="offboardingStep" touchless style="width: 100%">
        <v-window-item :value="1">
          <StartedOffboarding
            @started="(offboardingStep = 2), setOffboardingStep(2)"
          />
        </v-window-item>
        <v-window-item :value="2">
          <UserSelection
            @back="setOffboardingStep(1)"
            @next="setOffboardingStep(3)"
          />
        </v-window-item>
        <v-window-item :value="3">
          <UserDigitalInventory
            @back="setOffboardingStep(2)"
            @next="setOffboardingStep(4)"
            @saveLog="saveAnalytics = $event"
          />
        </v-window-item>
        <v-window-item :value="4">
          <TransferEmail
            @back="setOffboardingStep(3)"
            @next="setOffboardingStep(5)"
          />
        </v-window-item>
        <v-window-item :value="5">
          <TransferDrive
            @back="setOffboardingStep(4)"
            @next="setOffboardingStep(6)"
          />
        </v-window-item>
        <v-window-item :value="6">
          <LicenseAssignment
            @back="setOffboardingStep(5)"
            @next="setOffboardingStep(7)"
          />
        </v-window-item>
        <v-window-item :value="7">
          <ConcludedOffBoarding
            @back="setOffboardingStep(7)"
            @goHome="goHome"
          />
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import StartedOffboarding from "@/components/offboardingUser/StartedOffboarding.vue";
import OffboardingProgressBar from "@/components/offboardingUser/OffboardingProgressBar.vue";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import UserSelection from "@/components/offboardingUser/UserSelection.vue";
import UserDigitalInventory from "@/components/offboardingUser/UserDigitalInventory.vue";
import TransferEmail from "@/components/offboardingUser/TransferEmail.vue";
import TransferDrive from "@/components/offboardingUser/TransferDrive.vue";
import LicenseAssignment from "@/components/offboardingUser/LicenseAssignment.vue";
import ConcludedOffBoarding from "@/components/offboardingUser/ConcludedOffBoarding.vue";

export default {
  name: "OffboardingUser",
  components: {
    StartedOffboarding,
    OffboardingProgressBar,
    UserSelection,
    UserDigitalInventory,
    TransferEmail,
    LicenseAssignment,
    ConcludedOffBoarding,
    TransferDrive,
  },
  data() {
    return {
      show: true,
      stepsNames: {
        1: "welcome",
        2: "user_selection",
        3: "inventory",
        4: "transfer_emails",
        5: "transfer_drive",
        6: "licences",
        7: "conclusion",
      },
    };
  },
  computed: {
    ...mapGetters(["currentOffboardingStep", "company", "mainDomain"]),

    offboardingStep: {
      get() {
        return this.currentOffboardingStep;
      },
      set(newStep) {
        this.setOffboardingStep(newStep);
      },
    },
  },
  methods: {
    ...mapMutations([
      "setOffboardingStep",
      "setGoogleWorkspaceLicensesAssigned",
      "setInitiatedMigration",
    ]),
    setFirebaseEvent,
    saveAnalytics({ event, click }) {
      if (this.mainDomain) {
        this.setFirebaseEvent(this.mainDomain, event, click);
      }
    },

    removeStorageData() {
      localStorage.removeItem("payload");
    },
    resetAll() {
      this.removeStorageData();
      this.setOffboardingStep();
      this.setGoogleWorkspaceLicensesAssigned([]);
      this.setInitiatedMigration();
    },

    goHome() {
      this.$router.push({ name: "Home" });
      setTimeout(this.resetAll, 500);
      const log = {
        event: "finish_offboarding",
        click: "Botão de finalizar offboarding de usuário",
      };
      this.saveAnalytics(log);
    },

    cancel() {
      this.$router.push({ name: "Home" });
      const log = {
        event: `cancel_offboarding_on_step_${
          this.stepsNames[this.currentOffboardingStep]
        }`,
        click:
          "Botão de fechar no canto superior esquerdo do fluxo de offboarding",
      };
      this.saveAnalytics(log);
    },
  },
};
</script>
